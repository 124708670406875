<template>
    <datastore-viewer :document-id="documentId" />
</template>

<script>
    import DatastoreViewer from './Datastore/Viewer.vue';

    export default {
        name: 'ManageDatastoreViewPage',

        components: {
            DatastoreViewer,
        },

        params: {
            documentId: {
                type: String,
                default: undefined,
            },
        },
    };
</script>
